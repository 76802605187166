import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import configureStore from './store/configureStore';
import Root from './containers/Root';
import Config from './config';
import { startErrorLogging } from './logger';

/// const { store, persistor } = configureStore();
import { store, persistor } from './store';

ReactGA.initialize(Config.GOOGLE_ANALYTICS_ID);

// enable remote logging
startErrorLogging();

ReactDOM.render(
  <Root store={store} persistor={persistor} />,
  document.getElementById('root')
);
